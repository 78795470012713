<template>
    <div class="content">
        <div class="title">在成长中托举</div>
        <div class="small_title">基于教师与学生在应用过程中沉淀下来的教与学行为大数据，促使本学校成为具有区域影响力的品牌学校</div>
        <div class="contentDetail">
            <div class="detail1">
              挖掘学科规律
            </div>
            <div class="detail2">
              淬炼办学特色
            </div>
            <div class="detail3">
              优化人才培养路径
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'school',
    data () {
        return {

        }
    }
}
</script>

<style scoped lang="less">
    .content{
      width: 1140px;
      height: 440px;
      margin: auto;
      margin-top: 20px;
      text-align: center;
      color: #000000;
      .title{
        padding-top: 40px;
        font-size: 32px;
        font-family: AlibabaPuHuiTi-Bold, AlibabaPuHuiTi;
        font-weight: bold;
        line-height: 60px;
        letter-spacing: 20px;
      }
      .small_title{
       line-height: 30px;
       text-indent: -20px;
      }
      .contentDetail{
        display: flex;
        flex-wrap: wrap;
        .detail1,.detail2,.detail3{
          width: 367px;
          height: 260px;
          line-height: 260px;
          margin-top: 20px;
          text-align: center;
          text-shadow: 0px 2px 10px #421E00;
          color:#fff;
          font-size: 28px;
          &:hover{
            transform:scale(1.02);
          }
        }
        &>div:nth-child(2n){
          margin-left: 19.5px;
        }
        &>div:nth-child(3n){
          margin-left: 19.5px;
        }
        .detail1{
          background: url('../../assets/img/28.png');
          background-size: 100% 100%;
        }
        .detail2{
          background: url('../../assets/img/29.png');
          background-size: 100% 100%;
        }
        .detail3{
          background: url('../../assets/img/30.png');
          background-size: 100% 100%;
        }
      }
    }
</style>